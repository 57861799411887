.Container {
  position: relative;

  display: inline-flex;
  flex-direction: column;

  background-color: transparent;
}

.ListBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0;

  list-style: none;
}

.Label {
  display: flex;
  justify-content: space-between;
  margin-bottom: calc(var(--offset-small) / 2);

  color: var(--color-label);
  font-size: var(--font-small);

  .Required {
    font-size: var(--font-tiny);
  }
}

.Input {
  width: 100%;
  padding: var(--offset-medium);

  color: var(--color-font);
  font-size: var(--font-medium);

  background-color: var(--color-input-background);

  border: var(--border-width) solid var(--color-border);
  border-radius: var(--border-radius);
  outline: none;

  transition: all var(--transition) ease-in-out;

  &:focus {
    background-color: var(--color-input-background-focus);
    border: var(--border-width) solid var(--color-border-focus);
  }

  &:disabled {
    border: var(--border-width) dashed var(--color-border-disabled);
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    cursor: not-allowed;
  }

  &.Error {
    background-color: var(--color-input-background-error);
    border-color: var(--color-border-error);
  }
}

.ListBoxContainer {
  position: absolute;
  bottom: 0;

  width: 100%;
  height: 13.2rem;
  overflow-y: auto;

  border-bottom-right-radius: var(--border);
  border-bottom-left-radius: var(--border);

  animation: open var(--transition) ease-in-out forwards;

  &.Closed {
    animation: close var(--transition) ease-in-out forwards;
  }
}

@keyframes open {
  0% {
    display: inline-block;

    transform: translateY(0%);
    opacity: 0;
  }

  100% {
    transform: translateY(100%);
    opacity: 1;
  }
}

@keyframes close {
  0% {
    transform: translateY(100%);
    opacity: 1;
  }

  100% {
    transform: translateY(0);
    opacity: 0;
  }
}

.MultiOptionList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  margin-bottom: var(--offset-small);
  padding: 0;
}

.MultiOption {
  margin-right: var(--offset-small);
  margin-bottom: var(--offset-small);
  padding: var(--offset-tiny) var(--offset-small);

  color: var(--color-font);
  font-size: var(--font-medium);

  list-style: none;
  background-color: var(--color-card-background);
  border: var(--border-width) solid;
  border-radius: var(--border-radius);
}

.RemoveMultiOption {
  color: var(--color-font);
  font-size: var(--font-medium);

  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  appearance: none;
}
