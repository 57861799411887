:root {
  --CaptionColor: var(--color5);
}

:global(.Dark) {
  --CaptionColor: var(--color6);
}

.Container {
  margin: var(--offsetM) var(--offsetZero);

  color: var(--CaptionColor);
  font-weight: 500;
  font-size: var(--fontS);
  line-height: var(--lineHeightM);
}
