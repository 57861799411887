$boxShadow: rgb(60 64 67 / 30%) 0 1px 2px 0, rgb(60 64 67 / 15%) 0 1px 3px 1px;
$inputHeight: 37px;
$inputPaddingLeft: 37px;
$optionHeight: 2.5rem; // 40px
$searchIconOffset: 5px;
$titleGap: 0.75rem; // 12px

.Container {
  position: relative;

  display: flex;
  gap: $titleGap;
  width: 100%;
}

.SearchIcon {
  position: absolute;
  top: $searchIconOffset;
  left: $searchIconOffset;
  z-index: var(--zIndexS);

  --SVGIconStroke: var(--color8);
  --SVGIconStrokeHover: var(--color8);
}

.Autocomplete {
  position: relative;

  display: flex;

  --offset-medium: var(--offsetS);
  --offset-small: var(--offsetS);
  --offset-tiny: var(--offsetXS);
  --border-radius: var(--borderRadius);
  --border-width: var(--borderWidth);
  --color-font: var(--fontM);
  --color-input-background: var(--color2);
  --color-input-background-focus: var(--color6);
  --color-border-focus: var(--color7);
  --color-card-background: var(--color16);
  --color-border: var(--color7);
  --color-selected-background: var(--color2);
  --color-hover-background: var(--color2);
  --font-medium: var(--fontS);
  --option-height: #{$optionHeight};
  --transition: var(--transitionNormal);

  input {
    padding-left: $inputPaddingLeft;
  }

  [role="listbox"] {
    position: absolute;
    top: $inputHeight;
    left: 0;

    margin: var(--offsetZero);

    box-shadow: $boxShadow;
  }
}
