.Container {
  width: 100%;
  overflow: hidden;

  background-color: var(--color9);
  border-radius: var(--borderRadius);

  .EditorContent {
    // !important is used in order to override style applied from the lib
    padding: var(--offsetM) !important;

    color: var(--color11) !important;
    font-size: var(--fontS) !important;
    font-family: var(--fontFamilyCode) !important;
  }
}
