:root {
  --ArticleHeaderBorderColor: var(--color7);
}

:global(.Dark) {
  --ArticleHeaderBorderColor: var(--color6);
}

.Container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: var(--offsetXS) solid var(--ArticleHeaderBorderColor);
}
