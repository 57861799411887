.Container {
  display: flex;
  flex-wrap: wrap;

  padding: var(--offsetS);

  * {
    font-weight: 400;
    font-size: var(--fontS);
    line-height: var(--lineHeightS);
  }
}
