:root {
  --ParagraphColor: var(--color9);
}

:global(.Dark) {
  --ParagraphColor: var(--color7);
}

.Container {
  margin: var(--offsetM) var(--offsetZero);

  color: var(--ParagraphColor);
  font-weight: 400;
  font-size: var(--fontS);
  line-height: var(--lineHeightL);
}
