$boxShadow: rgb(60 64 67 / 30%) 0 1px 2px 0, rgb(60 64 67 / 15%) 0 1px 3px 1px;
$menuWidth: 34rem;

:root {
  --RightMenuBackgroundColor: var(--color2);
  --RightMenuToggleBackgroundColor: var(--color1);
}

:global(.Dark) {
  --RightMenuBackgroundColor: var(--color1);

  .MenuIcon {
    --SVGIconStroke: var(--color4);
    --SVGIconStrokeHover: var(--color6);
  }

  .ToggleMenu {
    --RightMenuToggleBackgroundColor: var(--color2);
  }
}

.Container {
  position: relative;
  z-index: var(--zIndexSideMenu);

  display: flex;
  flex-direction: column;
  width: $menuWidth;
  height: 100%;
  padding: var(--offsetZero);
  padding-left: var(--offsetM);

  background-color: var(--RightMenuBackgroundColor);
  box-shadow: $boxShadow;

  transition: all var(--transitionNormal) ease-in-out;

  .ToggleMenu {
    display: none;
  }

  @media all and (max-width: 1443px) {
    position: absolute;
    top: var(--offsetZero);
    right: var(--offsetZero);

    margin-right: -$menuWidth;

    &.Opened {
      margin-right: var(--offsetZero);

      cursor: pointer;

      .ToggleMenu {
        display: none;
      }
    }

    .ToggleMenu {
      position: absolute;
      top: var(--offsetS);
      left: -1.9rem;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 1.9rem;
      height: 3rem;

      background-color: var(--RightMenuToggleBackgroundColor);
      border: none;
      border-top-left-radius: var(--borderRadius);
      border-bottom-left-radius: var(--borderRadius);
      cursor: pointer;

      animation: toggle-enter var(--transitionNormal) ease-in-out;

      &.ToggleMenuHide {
        display: none;
      }

      .MenuIcon {
        --SVGIconStroke: var(--color4);
        --SVGIconStrokeHover: var(--color6);
      }
    }
  }

  @media all and (max-width: 639px) {
    &.Opened {
      width: 100%;

      cursor: pointer;

      .ToggleMenu {
        display: none;
      }
    }
  }
}

@keyframes toggle-enter {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translate(0);
  }
}

.ScrollContainer {
  padding-right: var(--offsetM);
}
