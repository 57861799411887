.Container {
  color: var(--color1);
  font-weight: 400;

  &.Generic {
    color: var(--color3);
  }

  &.Method {
    color: var(--color19);
  }
}
