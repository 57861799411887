:root {
  --SVGIconStroke: var(--color8);
  --SVGIconStrokeHover: var(--color5);
}

:global(.Dark) {
  --SVGIconStroke: var(--color6);
  --SVGIconStrokeHover: green;
}

.Container {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    * {
      transition: all var(--transitionNormal) ease-in-out;

      stroke: var(--SVGIconStroke);
    }
  }

  &:hover {
    svg {
      * {
        stroke: var(--SVGIconStrokeHover);
      }
    }
  }
}

.Opaque {
  cursor: pointer;
  opacity: 0;

  transition: all var(--transitionNormal) ease-in-out;

  &:hover {
    opacity: 1;
  }
}
