$scrollerRight: 4px;
$scrollerWidth: 6px;
$scrollerBorderRadius: 4px;
$scrollTrackWidth: 14px;

.ScrollWrap {
  width: 100% !important;
  height: 100% !important;

  &:hover .Scroller {
    opacity: 1;
  }
}

.ScrollContainer {
  width: 100%;
  height: 100%;
}

.Scroller {
  position: absolute;
  right: $scrollerRight;
  z-index: var(--zIndexS);

  width: $scrollerWidth;

  background-color: var(--color7);
  border-radius: $scrollerBorderRadius;
  opacity: 0;

  transition: opacity var(--transitionNormal) ease-in-out,
    background-color var(--transitionNormal) ease-in-out !important;

  &:hover {
    background-color: var(--color8);
  }

  &.ScrollerMoving {
    background-color: var(--color3);
  }
}

.ScrollTrack {
  right: 0;
  z-index: var(--zIndexS);

  width: $scrollTrackWidth;

  background-color: var(--transparent);
  cursor: pointer;
}
