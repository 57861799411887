:root {
  --TooltipBackgroundColor: var(--color6);
  --TooltipBorderColor: var(--color8);
  --TooltipColor: var(--color1);
}

:global(.Dark) {
  --TooltipBackgroundColor: var(--color1);
  --TooltipBorderColor: var(--color8);
  --TooltipColor: var(--color6);
}

.Container {
  display: flex;

  .Tooltip {
    position: fixed;
    z-index: var(--zIndexZero);

    display: flex;
    padding: 1.5rem;
    overflow: auto;

    color: var(--TooltipColor);

    background-color: var(--TooltipBackgroundColor);
    border: var(--offsetXS) solid var(--TooltipBorderColor);
    border-radius: var(--borderRadius);
    box-shadow: rgb(50 50 93 / 25%) 0 2px 5px -1px, rgb(0 0 0 / 30%) 0 1px 3px -1px;
    transform: scale3d(0.1, 0.1, 0.1);
    opacity: 0;

    transition: all var(--transitionNormal) ease-in-out;
  }

  &:focus,
  &:hover {
    .Tooltip {
      z-index: var(--zIndexTooltip);

      transform: scale3d(1, 1, 1);
      opacity: 1;
    }
  }
}
