.Container {
  display: flex;
  margin-right: var(--offsetS);

  color: var(--color13);
  font-weight: 700;
  word-break: break-all;

  * {
    font-weight: 700;
    word-break: break-all;
  }

  &.NoMargin {
    margin-right: var(--offsetZero);
  }
}
