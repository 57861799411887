.Container {
  color: var(--color8);

  &.MarginLeft {
    margin-left: var(--offsetS);
  }

  &.MarginRight {
    margin-right: var(--offsetS);
  }
}
