.Container {
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--option-height);
  padding: 0;
  padding-left: var(--offset-small);
  overflow: hidden;

  color: var(--color-font);
  font-size: var(--font-medium);
  line-height: var(--font-medium);

  list-style: none;
  background-color: var(--color-card-background);
  border: none;
  border-bottom: 1px solid var(--color-border);
  cursor: pointer;

  transition: all var(--transition) ease-in-out;

  appearance: none;

  &.Selected {
    color: var(--color-button);

    background-color: var(--color-selected-background);
  }

  &:focus,
  &:hover {
    color: var(--color-button);

    background-color: var(--color-hover-background);
    outline: none;
  }
}
