.List {
  display: inline-block;
  width: 100%;
  margin: 0;
  margin-top: var(--offset-tiny);
  padding: 0;
  overflow-y: hidden;

  background-color: transparent;
  border-radius: var(--border-radius);

  transition: height var(--transition) ease-in-out;
}
