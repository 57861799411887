:root {
  --LinkColor: var(--color9);
  --LinkBorderColor: var(--color9);
}

:global(.Dark) {
  --LinkColor: var(--color6);
  --LinkBorderColor: var(--color6);
}

.Container {
  display: inline-flex;

  color: var(--LinkColor);
  font-weight: 700;
  line-height: var(--lineHeightS);
  text-decoration: none;

  border-bottom: var(--offsetXS) solid transparent;

  transition: all var(--transitionNormal) ease-in-out;

  &.Selected {
    font-weight: 900;
  }

  &:hover {
    border-bottom: var(--offsetXS) solid var(--LinkBorderColor);
  }
}
